<template>
   <v-card>
      <v-card-title class="d-flex justify-start">
         <v-card-subtitle class="pt-0 pb-0 indigo--text text--darken-4 font-weight-bold">To add all unassigned patients
                                                                                         for a customer, select the
                                                                                         customer and press "Assign to
                                                                                         Pod"
         </v-card-subtitle>
         <v-card-subtitle class="pt-1 pb-0 indigo--text text--darken-4 font-weight-bold">To add all unassigned patients
                                                                                         for a site, select the
                                                                                         customer, then select the site
                                                                                         and press "Assign to Pod"
         </v-card-subtitle>
      </v-card-title>
      <v-container class="pt-0">
         <v-card-text class="pt-0">
            <v-form ref="form" v-model="valid" lazy-validation>
               <v-row>
                  <v-col col="6">
                     <v-select ref="customers" :items="customerItems" label="Customers" item-text="name" item-value="customerId" :rules="customerTypeRule"
                               required v-model="selectedCustomer" @change="getCustomerData" class="ml-4"></v-select>
                  </v-col>
               </v-row>
               <v-row>
                  <v-col col="6">
                     <SiteSearch :key="siteSearchKey" v-on:cleared="resetPatientSelections" v-on:site-selected="getSitePatients" :isReadonly="selectedCustomer == null"
                                 :customerId="selectedCustomer" />
                  </v-col>
               </v-row>
               <v-row>
                  <v-col col="6">
                     <v-alert class="ml-4" v-if="displayNoPatientsAlert" dense elevation="3" text color="indigo darken-4" type="info">
                        No Unassigned Patients
                     </v-alert>
                  </v-col>
               </v-row>
               <v-row>
                  <v-col col="6">
                     <v-autocomplete ref="patients" v-if="patientsWithoutPod" v-model="selectedPatients"
                                     :items="patientsWithoutPod" :loading="loading" @change="clearSearch()"
                                     :search-input.sync="search" item-value="patientId"
                                     :return-object="true" dense no-filter multiple chips
                                     label="Unassigned Patients" item-text="fullName" class="ml-4">
                        <template v-slot:selection="data">
                           <v-chip v-bind="data.attrs" :input-value="data.selected.patientId" :key="data.item.patientId" close @click="data.select"
                                   @click:close="removeSelectedUser(data.item.patientId)">
                              {{data.item.lastName + ' ' + data.item.firstName}}
                           </v-chip>
                        </template>
                        <template v-slot:item="data">
                           <v-list-item-content>
                              <v-list-item-subtitle v-html="data.item.lastName + ' ' + data.item.firstName"></v-list-item-subtitle>
                           </v-list-item-content>
                        </template>
                        <template v-slot:append-item>
                           <div v-intersect="endIntersect"/>
                        </template>
                     </v-autocomplete>
                  </v-col>
               </v-row>
               <v-container style="height: 80px;" v-show="isInProgress">
                  <v-row class="fill-height" align-content="center" justify="center">
                     <v-col class="text-subtitle-1 text-center indigo--text text--darken-4 font-weight-bold" cols="12">
                        Adding to Pod
                     </v-col>
                     <v-col cols="6">
                        <v-progress-linear color="indigo darken-4" indeterminate rounded height="6"></v-progress-linear>
                     </v-col>
                  </v-row>
               </v-container>
            </v-form>
         </v-card-text>
         <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="onCancel">Cancel</v-btn>
            <v-btn color="blue darken-1" text @click="onReset">Reset</v-btn>
            <v-btn color="blue darken-1" text @click="onSave" :disabled="disabledAdd">Assign to Pod</v-btn>
         </v-card-actions>
      </v-container>
   </v-card>
</template>

<script>
   import {
      GetCustomersList,
      GetCustomerPatientList,
      GetCustomerSiteList,
      GetCustomerSitePatientList
   } from "./queries/add-patients.graphql";
   import {
      AddCustomerPatientsToPod,
      AddSitePatientsToPod,
      AddPodPatients
   } from "./mutations/add-pod-patients.graphql";
   import {Input} from "@/types/queryInput";
   import SiteSearch from "@/components/SiteSearch.vue";
   import CustomerMasterService from "@/services/CustomerMasterService"
   
   export default {
      name: "AddPatient",
      props: {
         podId: Number
      },
      data: () => ({
         initialLoad: true,
         skipGetCustomersList: true,
         skipGetCustomersSites: true,
         skipGetCustomerPatientList: true,
         skipGetCustomerSitePatientList: true,
         customerItems: [],
         sites: [],
         customerPatientsWithoutPod: [],
         sitePatientsWithoutPod: [],
         allLoadedPatients: [],
         valid: false,
         customerTypeRule: [
            v => !!v || "Customer is required"
         ],
         selectedCustomer: null,
         selectedSite: null,
         selectedPatients: [],
         isInProgress: false,
         disabledAdd: false,
         count: 0,
         page: 1,
         itemsPerPage: 20,
         search: null,
         searchWord: "",
         loading: false,
         patientsLabel: "Unassigned Patients",
         siteSearchKey: 0
      }),
      components: {
         SiteSearch
      },
      computed: {
         customerInput() {
            return new Input();
         },
         siteInput() {
            return new Input();
         },
         patientInput() {
            let input = new Input();
            input.take = this.itemsPerPage;
            input.skip = this.itemsPerPage * (this.page - 1);
            input.searchWord = this.searchWord;
            
            return input;
         },
         patientsWithoutPod() {
            if (this.selectedSite) {
               return this.sitePatientsWithoutPod;
            }
            if (this.selectedCustomer) {
               return this.customerPatientsWithoutPod;
            }
            return [];
         },
         patientCount() {
            return this.count ? this.count : this.itemsPerPage;
         },
         pageCount() {
            return Math.ceil(this.patientCount / this.itemsPerPage);
         },
         displayNoPatientsAlert() {
            return this.patientsWithoutPod.length === 0 && this.selectedCustomer;
         }
      },
      async mounted() {
         this.getCustomers();
      },
      methods: {
         triggerCustomerListQuery() {
            if (!this.initialLoad) {
               return;
            }
            this.$apollo.queries.customers.skip = false;
            this.$apollo.queries.customers.refetch();
            this.initialLoad = false;
         },
         getCustomerData(customerId) {
            this.selectedCustomer = customerId;
            
            this.resetSiteSelections();
            
            this.resetPatientSelections();
            this.getCustomerPatients();
            this.$apollo.queries.sitePatientsWithoutPod.skip = true;
         },
         getCustomerPatients() {
            if (this.selectedCustomer == null) {
               return;
            }
            
            this.$apollo.queries.customerPatientsWithoutPod.skip = false;
            this.$apollo.queries.customerPatientsWithoutPod.refetch();
         },
         getSitePatients(site) {
            this.selectedSite = site.siteId;
            
            this.resetPatientSelections();
            this.$apollo.queries.sitePatientsWithoutPod.skip = false;
            this.$apollo.queries.sitePatientsWithoutPod.refetch();
         },
         async getCustomers() {
                await new CustomerMasterService()
                    .getCustomer()
                    .then((value) => {
                        this.customerItems = value;
                    })
            },
         resetCustomerSelection() {
            this.$refs.customers.reset();
            this.selectedCustomer = null;
            this.$apollo.queries.customerPatientsWithoutPod.skip = true;
            this.$apollo.queries.sitePatientsWithoutPod.skip = true;
         },
         resetSiteSelections() {
            this.siteSearchKey++;
            this.selectedSite = null;
            this.$apollo.queries.sites.skip = true;
         },
         resetPatientSelections() {
            this.$refs.patients.reset();
            this.searchWord = "";
            this.selectedPatients = [];
            this.allLoadedPatients = [];
            this.page = 1;
         },
         fullName(item) {
            if (item == null) {
               return "";
            }
            return `${item.lastName}, ${item.firstName}`;
         },
         removeSelectedUser(itemId) {
            let idx = null;
            this.selectedPatients.findIndex((element, index) => {
               if (element.patientId === itemId) {
                  idx = index;
               }
            });
            if (idx == null) {
               return;
            }
            this.selectedPatients.splice(idx, 1);
            this.selectedPatients = [...this.selectedPatients];
         },
         onReset() {
            this.resetCustomerSelection();
            this.resetSiteSelections();
            this.resetPatientSelections();
         },
         onCancel() {
            this.$emit("canceled");
         },
         async onSave() {
            if (!this.$refs.form.validate()) {
               let options = {
                  text: "Please fix the highlighted issues before savings",
                  title: "Unable to save",
                  actions: ["Okay"]
               };
               await this.$dialog.error(options);
               return;
            }
            this.disabledAdd = true;
            this.isInProgress = true;
            
            if (this.selectedPatients.length === 0 && this.selectedSite == null) {
               await this.addAllCustomerPatientsToPod();
               return;
            }
            
            if (this.selectedPatients.length === 0 && this.selectedSite != null) {
               await this.addAllSitePatientsToPod();
               return;
            }
            
            await this.addPodPatients();
         },
         async addAllCustomerPatientsToPod() {
            await this.$apollo
                      .mutate(
                          {
                             mutation: AddCustomerPatientsToPod,
                             variables: {
                                input: {
                                   podId: this.podId,
                                   customerId: this.selectedCustomer,
                                   updatedByUserId: this.$store.state.achillesUser.userId
                                }
                             }
                          })
                      .then(async (data) => {
                         if (data.data.payload.error) {
                            return await this.failure(data.data.payload.errorCode);
                         }
                         return await this.success();
                      })
                      .catch(async (error) => {
                         await this.failure(error);
                      });
         },
         async addAllSitePatientsToPod() {
            await this.$apollo
                      .mutate(
                          {
                             mutation: AddSitePatientsToPod,
                             variables: {
                                input: {
                                   podId: this.podId,
                                   customerId: this.selectedCustomer,
                                   siteId: this.selectedSite,
                                   updatedByUserId: this.$store.state.achillesUser.userId
                                }
                             }
                          })
                      .then(async (data) => {
                         if (data.data.payload.error) {
                            return await this.failure(data.data.payload.errorCode);
                         }
                         return await this.success();
                      })
                      .catch(async (error) => {
                         await this.failure(error);
                      });
         },
         async addPodPatients() {
            let patientIds = [];
            this.selectedPatients.forEach((item) => {
               patientIds.push(item.patientId);
            });
            
            await this.$apollo
                      .mutate(
                          {
                             mutation: AddPodPatients,
                             variables: {
                                input: {
                                   podId: this.podId,
                                   customerId: this.selectedCustomer,
                                   patientIds: patientIds,
                                   updatedByUserId: this.$store.state.achillesUser.userId
                                }
                             }
                          })
                      .then(async (data) => {
                         if (data.data.payload.error) {
                            return await this.failure(data.data.payload.errorCode);
                         }
                         return await this.success();
                      })
                      .catch(async (error) => {
                         await this.failure(error);
                      });
         },
         async success() {
            this.clearApolloCache();
            
            this.isInProgress = false;
            this.disabledAdd = false;
            
            let options = {
               text: "Pod patients saved successfully",
               title: "Saved",
               actions: ["Okay"]
            };
            await this.$dialog.info(options);
            
            this.$emit("saved");
         },
         clearApolloCache() {
            let clients = Object.values(this.$apollo.provider.clients);
            clients.forEach(client => client.cache.reset());
         },
         async failure(message) {
            this.isInProgress = false;
            let options = {
               text: message,
               title: "Unable to save",
               actions: ["Okay"]
            };
            await this.$dialog.error(options);
         },
         endIntersect(entries, observer, isIntersecting) {
            if (isIntersecting) {
               this.page += 1;
               this.allLoadedPatients = this.allLoadedPatients.concat(this.patientsWithoutPod);
               this.getPatients();
            }
         },
         getPatients(){
            this.loading = true;
            
            if(this.selectedSite){
               this.$apollo.queries.sitePatientsWithoutPod.skip = false;
               this.$apollo.queries.sitePatientsWithoutPod.refetch();
               
               return;
            }
   
            this.$apollo.queries.customerPatientsWithoutPod.skip = false;
            this.$apollo.queries.customerPatientsWithoutPod.refetch();
         },
         clearSearch(){
            this.searchWord = "";
            this.allLoadedPatients = [];
            this.getPatients();
         }
      },
      apollo: {
         customers: {
            query: GetCustomersList,
            variables() {
               return {
                  input: this.customerInput
               };
            },
            update(data) {
               return data.customers.data.sort(
                   (a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1);
            },
            // Disable query on component load.
            // We need to execute query when component is created and props are passed 
            skip() {
               return this.skipGetCustomersList;
            }
         },
         sites: {
            query: GetCustomerSiteList,
            variables() {
               return {
                  siteInput: this.siteInput,
                  customerId: this.selectedCustomer
               };
            },
            update(data) {
               return data.customer.sites.data.sort(
                   (a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1);
            },
            skip() {
               return this.skipGetCustomersSites;
            }
         },
         customerPatientsWithoutPod: {
            query: GetCustomerPatientList,
            variables() {
               return {
                  patientInput: this.patientInput,
                  customerId: this.selectedCustomer
               };
            },
            update(data) {
               let sorted = data.customer.patientsWithoutPod.data.sort(
                     (a, b) => a.lastName.toLowerCase() > b.lastName.toLowerCase() ? 1 : -1);
               this.count = data.customer.patientsWithoutPod.count;
               this.disabledAdd = (this.patientsWithoutPod.length === 0 && sorted.length === 0);
               this.patientsLabel = "No Unassigned Patients";
               
               return sorted;
            },
            async result(data) {

               if (this.page > 1) {
                  this.customerPatientsWithoutPod = this.allLoadedPatients.concat(this.customerPatientsWithoutPod);
               }
               if (this.selectedPatients.length > 0) {
                  this.customerPatientsWithoutPod = this.customerPatientsWithoutPod.concat(this.selectedPatients);
               }
      
               this.loading = data.loading;
            },
            skip() {
               return this.skipGetCustomerPatientList;
            }
         },
         sitePatientsWithoutPod: {
            query: GetCustomerSitePatientList,
            variables() {
               return {
                  patientInput: this.patientInput,
                  siteId: this.selectedSite,
                  customerId: this.selectedCustomer
               };
            },
            update(data) {
               let sorted = data.customer.site.patientsWithoutPod.data.sort(
                     (a, b) => a.lastName.toLowerCase() > b.lastName.toLowerCase() ? 1 : -1);
               this.count = data.customer.site.patientsWithoutPod.count;
               this.disabledAdd = (this.patientsWithoutPod.length === 0 && sorted.length === 0);
               this.patientsLabel = "No Unassigned Patients";
               
               return sorted;
            },
            async result(data) {
               
               if (this.page > 1) {
                  this.sitePatientsWithoutPod = this.allLoadedPatients.concat(this.sitePatientsWithoutPod);
               }
               if (this.selectedPatients.length > 0) {
                  this.sitePatientsWithoutPod = this.sitePatientsWithoutPod.concat(this.selectedPatients);
               }
      
               this.loading = data.loading;
            },
            skip() {
               return this.skipGetCustomerSitePatientList;
            }
         }
      },
      created() {
         this.triggerCustomerListQuery();
      },
      watch: {
         search(val) {
            if (val && typeof val !== 'undefined' && val.length >= 3 && this.searchWord !== val) {
               this.searchWord = val;
               this.allLoadedPatients = [];
               this.page = 1;
               this.getPatients();
               return;
            }
   
            if (!val || (val.length === 0 && this.searchWord !== val)){
               this.searchWord = "";
               this.allLoadedPatients = [];
               this.page = 1;
               this.getPatients();
            }
         }
      }
   };
</script>

<style scoped lang="scss">

</style>