<template>
  <v-card outlined tile elevation="2" class="mt-4">
    <v-row class="pb-0 ml-4">
      <v-col md="8">
        <v-card-title>Pod Patients</v-card-title>
      </v-col>
      <v-col md="3">
        <v-text-field v-model="search" append-icon="mdi-magnify"
                      label="Search" single-line hide-details learable
                      @click:clear="searchCleared">
        </v-text-field>
      </v-col>
    </v-row>
    <pod-description v-if="pod" :name="pod.name" :description="pod.description"></pod-description>
    <v-container style="height: 80px;" v-show="loading">
      <v-row class="fill-height" align-content="center" justify="center">
        <v-col class="text-subtitle-1 text-center indigo--text text--darken-4 font-weight-bold" cols="12">
          Loading Pod Patients
        </v-col>
        <v-col cols="6">
          <v-progress-linear color="indigo darken-4" indeterminate rounded height="6"></v-progress-linear>
        </v-col>
      </v-row>
    </v-container>
    <v-sheet class="pa-3">
      <v-data-table v-if="pod"
                    :headers="headers"
                    :items="patients"
                    class="elevation-1"
                    :options.sync="options"
                    :pageCount="pageCount"
                    :server-items-length="membershipCount"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :search="search"
                    :footer-props="{
                          showFirstLastPage: true,
                          'items-per-page-options':rowsPerPage
                        }">
        <template #[`item.customerDisplayName`]="{ item }">
            <template v-if="item.customerId == 1">
                {{ item.customerName }}
            </template>
            <template v-else>
                {{ customerDisplayName(item.customerId) }}
            </template>
        </template>
        <template v-slot:header.primaryNurseUser="{ header }">
          <th>
            <div>{{ header.text }}</div>
            <div v-if="filters.hasOwnProperty(header.value)" class="header-filter">
              <v-select dense hide-details
                        :items="filters[header.value]" :return-object="true"
                        item-text="text"
                        item-value="value"
                        v-model="selectedPrimaryNurseUserFilter"
                        :menu-props="{ top: false, offsetY: true }">
                <template v-slot:selection="{ item}">
                  <span class="filter-selection font-weight-regular caption black--text"
                        v-if="item.text.length>0">{{ item.text }}</span>
                </template>
              </v-select>
            </div>

          </th>
        </template>
        <template #[`item.actions`]="{ item }">
          <v-icon v-if="displayEdit && showEdit(item)" class="mr-3" @click="changePrimaryCareNurse(item)">mdi-content-save-check</v-icon>
          <v-icon v-if="displayEdit && !showEdit(item)" class="mr-3" @click="editItem(item)">mdi-pencil</v-icon>
          <v-icon v-if="displayEdit && showEdit(item)" class="mr-3" @click="editItem(item)">mdi-close-circle</v-icon>
          <v-icon v-if="displayDelete" @click="deletePatientDialog(item)">mdi-delete</v-icon>
        </template>
        <template #[`item.primaryNurseUser`]="{ item }">
          <v-progress-linear v-if="showEdit(item) && saving && !bulkInsert"
                             indeterminate
                             indigo darken-4></v-progress-linear>
          <div v-if="!saving">
            <v-select v-model="selectedPrimaryCareNurse" dense solo v-if="showEdit(item) && !bulkInsert"
                      class="primary-nurse-select" :items="item.pod.nurses.data" item-value="userId"
                      item-text="displayName" @input="changePrimaryCareNurse(item)"></v-select>
          </div>
          <span
              v-if="!showEdit(item) && !bulkInsert">{{
              item.primaryNurseUser ? item.primaryNurseUser.displayName : ''
            }}</span>
          <v-checkbox v-model="selectedPatients" @change="emitSelectedPatients" :value="selectedPatientInfo(item)"
                      v-if="bulkInsert"></v-checkbox>

        </template>
      </v-data-table>
    </v-sheet>
    <v-dialog v-model="deleteDialog" persistent max-width="500" content-class="confirm-dialog" v-if="deleteDialog">
      <v-card>
        <v-card-title class="headline primary">
          Confirm Delete
        </v-card-title>
        <v-card-text>Are you sure you want to delete Patient from this Pod?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="confirmDialogCallback(false)"> No</v-btn>
          <v-btn color="blue darken-1" text @click="confirmDialogCallback(true)"> Yes</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>

</template>


<script>
import GetPodPatients from "./queries/patients.graphql";
import {DeletePodPatients} from "./mutations/delete-pod-patients.graphql";
import {Input} from "@/types/queryInput";
import PodDescription from "../../Pods/PodDescription";
import {PodRoleEnum} from "@/types/enum";
import { CustomerEnum } from "@/types/enum";
import UpdatePatientPrimaryNurseUser from "./mutations/update-patient-primary-nurse-user.graphql";


export default {
  name: "PatientList",
  props: {
    podId: Number,
    displayDelete: Boolean,
    displayEdit: Boolean,
    bulkInsert: Boolean,
    refreshPatientsList: Boolean
  },
  components: {
    PodDescription
  },
  data: () => ({
    selectedPatients: [],
    selectedPrimaryCareNurse: null,
    pod: null,
    loading: true,
    editRow: null,
    saving: false,
    itemsPerPage: 10,
    skipGetPodPatientsQuery: true,
    search: "",
    options: {
      page: 1
    },
    headers: [
      {
        text: "",
        value: "selects",
        sortable: false
      },
      {
        text: "Customer Name",
        align: "start",
        value: "customerDisplayName"
      },
      {
        text: "Patient First Name",
        value: "patientFirstName"
      },
      {
        text: "Patient Last Name",
        value: "patientLastName"
      },
      {
        text: "Primary Nurse",
        value: "primaryNurseUser",
        sortable: false
      },
      {
        text: "Actions",
        value: "actions",
        sortable: false
      }

    ],
    sortBy: "customerName",
    sortDesc: false,
    filters: {
      "primaryNurseUser": [
        {
          text: "",
          value: null
        },
        {
          text: "Patients with primary nurse",
          value: true
        },
        {
          text: "Patients without primary nurse",
          value: false
        }
      ]
    },
    selectedPrimaryNurseUserFilter: null,
    defaultPrimaryNurseUserFilter: {
      text: "All Patients",
      value: null
    },
    deleteDialog: false,
    itemToDelete: null,
  }),
  computed: {
    rowsPerPage() {
      return this.$rowsPerPage;
    },
    patients() {
      return this.pod && this.pod.patients ? this.pod.patients.data : [];
    },
    membershipCount() {
      return this.pod && this.pod.patients.count ? this.pod.patients.count : 0;
    },
    sortInput() {
      return {
        column: this.sortBy,
        order: this.sortDesc ? "desc" : "asc"
      };
    },
    podPatientInput() {
      let input = new Input();
      input.take = this.itemsPerPage;
      input.skip = this.itemsPerPage * (this.options.page - 1);
      input.sort = this.sortInput;
      input.searchWord = this.search;
      input.filter = {
        hasPrimaryNurse: typeof this.selectedPrimaryNurseUserFilter !== "undefined" &&
        this.selectedPrimaryNurseUserFilter !== null
            ? this.selectedPrimaryNurseUserFilter.value
            : null
      };
      return input;
    },
    pageCount() {
      if (this.membershipCount === 0) {
        return 0;
      }

      return this.itemsPerPage / this.membershipCount;
    }
  },
  methods: {
    selectedPatientInfo(item) {
      return {
        customerId: item.customerId,
        patientId: item.patientId,
        patientFirstName: item.patientFirstName,
        patientLastName: item.patientLastName
      }
    },
    customerDisplayName(item) {
        return CustomerEnum[item] || 'Unknown';
    },
    async changePrimaryCareNurse(item) {
      let input = {
        customerId: item.customerId,
        patientId: item.patientId,
        primaryNurseUserId: this.selectedPrimaryCareNurse,
        updatedByUserId: this.$store.state.achillesUser.userId
      };
      this.saving = true;
      await this.$apollo.mutate(
          {
            mutation: UpdatePatientPrimaryNurseUser,
            variables: {
              input: input
            }
          })
          .then(async (data) => {
            if (data.data.payload.error) {
              await this.$dialog.error({
                text: data.data.payload.errorCode,
                title: 'Unable to Save'
              });
            }
            await this.$apollo.queries.pod.refetch();
            this.saving = false;
            this.editRow = null;
          })
          .catch(async (error) => {
            console.log(error);
            await this.$dialog.error({
              text: 'An error occured while saving',
              title: 'Unable to Save'
            });
          });
    },
    showEdit(item) {
      return !!this.editRow && item.patientId === this.editRow.patientId && item.customerId === this.editRow.customerId;
    },
    triggerQuery() {
      this.loading = true;
      this.$apollo.queries.pod.skip = false;
      this.$apollo.queries.pod.refetch();
    },
    async editItem(item) {
      if (this.editRow === item) {
        this.editRow = null;
      } else {

        if (item.primaryNurseUser) {
          this.selectedPrimaryCareNurse = item.primaryNurseUser.userId;
        }

        this.editRow = item;
      }
    },
    async deleteItem() {
      await this.$apollo.mutate(
          {
            mutation: DeletePodPatients,
            variables: {
              input: {
                customerId: this.itemToDelete.customerId,
                podId: this.podId,
                updatedByUserId: this.$store.state.achillesUser.userId,
                patientIds: [this.itemToDelete.patientId]
              }
            }
          })
          .then(async (data) => {
            this.itemToDelete = null;

            if (data.data.payload.error) {
              return await this.failure(data.payload);
            }
            return await this.success();
          })
          .catch(async (error) => {
            this.itemToDelete = null;

            await this.failure(error);
          });

    },
    async success() {
      this.$emit("deleted");
    },
    async emitSelectedPatients() {
      this.$emit("patients", this.selectedPatients);
    },
    async failure(data) {
      let errorTest = "Unable to delete Patient from Pod";

      if (typeof data.errorCode !== undefined) {
        errorTest += `: ${data.errorCode}`;
      }
      //exception from API
      else {
        console.log(data);
      }

      let options = {
        text: errorTest,
        title: "Unable to delete",
        actions: ["Okay"]
      };

      await this.$dialog.error(options);
    },
    searchCleared() {
      this.search = "";
      this.triggerQuery();
    },
    deletePatientDialog(item) {
      this.deleteDialog = true;
      this.itemToDelete = item;
    },
    async confirmDialogCallback(agreed) {
      if (agreed) {
        await this.deleteItem();
      }
      this.deleteDialog = false;
      this.itemToDelete = null;
    }
  },
  apollo: {
    pod: {
      query: GetPodPatients,
      variables() {
        return {
          podId: this.podId,
          podPatientInput: this.podPatientInput,
          podRoleIds: [PodRoleEnum.PodNurse, PodRoleEnum.PodAdministrator, PodRoleEnum.PodSupervisor, PodRoleEnum.PodTeamLeader],
          assignedUsersByPodRoleInput: {
            take: 100,
            skip: 0
          }
        };
      },
      update(data) {
        this.skipGetPodPatientsQuery = true;
        this.loading = false;

        return data.pod;
      },
      // Disable query on component load.
      // We need to execute query when component is created and props are passed 
      skip() {
        return this.skipGetPodPatientsQuery;
      }
    }
  },
  created() {
    this.triggerQuery();
  },
  watch: {
    options: {
      handler() {
        const {
          sortBy,
          sortDesc,
          page,
          itemsPerPage
        } = this.options;

        this.itemsPerPage = itemsPerPage;
        this.sortBy = sortBy[0];
        this.sortDesc = sortDesc[0];

        this.triggerQuery();
      },
      deep: true
    },
    search: {
      handler(val) {
        if (val && typeof val === "undefined" || (val.length > 0 && val.length < 3)) {
          this.$apollo.queries.pod.skip = true;
          return;
        }
        this.search = val;
        this.options.page = 1;
        this.triggerQuery();
      },
      deep: true
    },
    selectedPrimaryNurseUserFilter: {
      handler(val) {
        if (!val || typeof val === "undefined") {
          return;
        }

        this.options.page = 1;
        this.triggerQuery();
      }
    }
  }
};
</script>

<style scoped lang="scss">
.v-chip {
  min-width: fit-content;
}
</style>
<style lang="scss">
.header-filter {
  .v-select__selections {
    max-width: fit-content;
    min-width: 100px;
    width: 180px;

    input {
      width: 0;
    }
  }
}

.filter-selection {
  white-space: nowrap;
}

.primary-nurse-select {
  height: 40px;
  max-width: 300px;
}
</style>