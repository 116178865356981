<template>
   <v-card>
      <v-card-title class="d-flex justify-start">
         <v-card-subtitle class="pt-0 pb-0 indigo--text text--darken-4 font-weight-bold">To remove all patients
                                                                                         for a customer, select the
                                                                                         customer and press "Delete from
                                                                                         Pod"
         </v-card-subtitle>
         <v-card-subtitle class="pt-1 pb-0 indigo--text text--darken-4 font-weight-bold">To remove all patients
                                                                                         for a site, select the
                                                                                         customer, then select the site
                                                                                         and press "Delete from Pod"
         </v-card-subtitle>
      </v-card-title>
      <v-container class="pt-0">
         <v-card-text class="pt-0">
            <v-form ref="form" v-model="valid" lazy-validation>
               <v-row>
                  <v-col col="6">
                     <v-select ref="customers" :items="customers" label="Customers" item-text="name" item-value="customerId" :rules="customerTypeRule"
                               required v-model="selectedCustomer" @change="getCustomerData" class="ml-4"></v-select>
                  </v-col>
               </v-row>
               <v-row>
                  <v-col col="6">
                     <v-select ref="sites" v-if="sites" :items="sites" label="Sites" item-text="name" item-value="siteId"
                               required v-model="selectedSite" @change="getSitePatients" class="ml-4"></v-select>
                  </v-col>
               </v-row>
               <v-row>
                  <v-col col="6">
                     
                     <v-combobox ref="patients" v-if="patientsWithPod" v-model="selectedPatients" :items="patientsWithPod" chips
                                 clearable label="Patients" multiple solo :return-object="true" :item-text="fullName" item-value="patientId" class="ml-4">
                        <!-- Total Count of Patients -->
                        <template v-slot:prepend-item>
                           <template>
                              <div class="text-center">
                                 <v-pagination v-model="page" :length="pageCount" :total-visible="7" circle @input="next"></v-pagination>
                              </div>
                           </template>
                        </template>
                        <template v-slot:selection="{ attrs, item, select, selected }">
                           <v-chip v-bind="item" :input-value="selected.patientId" :key="item.patientId" close @click="select"
                                   @click:close="removeSelectedUser(item.patientId)">
                              <span>{{item.lastName + ", " + item.firstName}}</span>
                           </v-chip>
                        </template>
                     </v-combobox>
                  </v-col>
               </v-row>
               <v-container style="height: 80px;" v-show="isInProgress">
                  <v-row class="fill-height" align-content="center" justify="center">
                     <v-col class="text-subtitle-1 text-center indigo--text text--darken-4 font-weight-bold" cols="12">
                        Removing from Pod
                     </v-col>
                     <v-col cols="6">
                        <v-progress-linear color="indigo darken-4" indeterminate rounded height="6"></v-progress-linear>
                     </v-col>
                  </v-row>
               </v-container>
            </v-form>
         </v-card-text>
         <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="onCancel">Cancel</v-btn>
            <v-btn color="blue darken-1" text @click="onReset">Reset</v-btn>
            <v-btn color="blue darken-1" text @click="onSave" :disabled="isInProgress">Remove from Pod</v-btn>
         </v-card-actions>
      </v-container>
   </v-card>
</template>

<script>
   import {
      GetPodCustomersList,
      GetPodCustomerPatientList,
      GetPodCustomerSiteList,
      GetPodCustomerSitePatientList
   } from "./queries/delete-patients.graphql";
   import {
      DeleteCustomerPatientsToPod,
      DeleteSitePatientsToPod,
      DeletePodPatients
   } from "./mutations/delete-pod-patients.graphql";
   import {Input} from "@/types/queryInput";
   
   export default {
      name: "DeletePatient",
      props: {
         podId: Number
      },
      data: () => ({
         initialLoad: true,
         valid: false,
         customers: [],
         sites: [],
         customerPatientsWithPod: [],
         sitePatientsWithPod: [],
         customerTypeRule: [
            v => !!v || "Customer is required"
         ],
         selectedCustomer: null,
         selectedSite: null,
         selectedPatients: [],
         createdByUserId: null,
         skipGetCustomersList: true,
         skipGetCustomerPatientList: true,
         skipGetCustomersSites: true,
         skipGetCustomerSitePatientList: true,
         isInProgress: false,
         count: 0,
         page: 1,
         itemsPerPage: 20
      }),
      computed: {
         patientsWithPod() {
            if (this.selectedSite) {
               return this.sitePatientsWithPod;
            }
            if (this.selectedCustomer) {
               return this.customerPatientsWithPod;
            }
            return [];
         },
         customerInput() {
            return new Input();
         },
         siteInput() {
            return new Input();
         },
         patientInput() {
            let input = new Input();
            input.take = this.itemsPerPage;
            input.skip = this.itemsPerPage * (this.page - 1);
            
            return input;
         },
         patientCount() {
            return this.count ? this.count : this.itemsPerPage;
         },
         pageCount() {
            return Math.ceil(this.patientCount / this.itemsPerPage);
         }
      },
      methods: {
         triggerCustomerListQuery() {
            if (!this.initialLoad) {
               return;
            }
            this.$apollo.queries.customers.skip = false;
            this.$apollo.queries.customers.refetch();
            this.initialLoad = false;
         },

         getCustomerData() {
            this.resetSiteSelections();
            
            this.resetPatientSelections();
            this.getCustomerSite();
            this.getCustomerPatients();
         },
         getSitePatients() {
            this.resetPatientSelections();
            this.$apollo.queries.sitePatientsWithPod.skip = false;
            this.$apollo.queries.sitePatientsWithPod.refetch();
         },
         getCustomerSite() {
            if (this.selectedCustomer == null) {
               return;
            }
            
            this.$apollo.queries.sites.skip = false;
            this.$apollo.queries.sites.refetch();
         },
         getCustomerPatients() {
            if (this.selectedCustomer == null) {
               return;
            }
            
            this.$apollo.queries.customerPatientsWithPod.skip = false;
            this.$apollo.queries.customerPatientsWithPod.refetch();
         },
         fullName(item) {
            if (item == null) {
               return "";
            }
            return `${item.lastName}, ${item.firstName}`;
         },
         removeSelectedUser(itemId) {
            let idx = null;
            this.selectedPatients.findIndex((element, index) => {
               if (element.patientId === itemId) {
                  idx = index;
               }
            });
            if (idx == null) {
               return;
            }
            this.selectedPatients.splice(idx, 1);
            this.selectedPatients = [...this.selectedPatients];
         },
         async onCancel() {
            this.$emit("canceled");
         },
         async onReset() {
            this.resetCustomerSelection();
            this.resetSiteSelections();
            this.resetPatientSelections();
         },
         async onSave() {
            if (!this.$refs.form.validate()) {
               let options = {
                  text: "Please fix the highlighted issues before savings",
                  title: "Unable to save",
                  actions: ["Okay"]
               };
               await this.$dialog.error(options);
               return;
            }
            this.isInProgress = true;
            this.createdByUserId = this.$store.state.achillesUser.userId;
            
            if (this.selectedPatients.length === 0 && this.selectedSite == null) {
               await this.deleteAllCustomerPatients();
               return;
            }
            
            if (this.selectedPatients.length === 0 && this.selectedSite != null) {
               await this.deleteAllSitePatients();
               return;
            }
            
            await this.deleteSelectedMemberships();
         },
         resetCustomerSelection() {
            this.$refs.customers.reset();
            this.selectedCustomer = null;
            this.$apollo.queries.customerPatientsWithPod.skip = true;
            this.$apollo.queries.sitePatientsWithPod.skip = true;
         },
         resetSiteSelections() {
            this.$refs.sites.reset();
            this.selectedSite = null;
            this.$apollo.queries.sites.skip = true;
         },
         resetPatientSelections() {
            this.$refs.patients.reset();
            this.selectedPatients = [];
         },
         async success() {
            this.clearApolloCache();
            
            this.isInProgress = false;
            let options = {
               text: "Patient successfully removed",
               title: "Deleted",
               actions: ["Okay"]
            };
            await this.$dialog.info(options);
            
            this.$emit("deleted");
         },
         clearApolloCache() {
            let clients = Object.values(this.$apollo.provider.clients);
            clients.forEach(client => client.cache.reset());
         },
         async failure(message) {
            this.isInProgress = false;
            let options = {
               text: message,
               title: "Unable to delete memberships",
               actions: ["Okay"]
            };
            await this.$dialog.error(options);
         },
         next() {
            if (this.selectedSite) {
               this.$apollo.queries.sitePatientsWithPod.skip = false;
               this.$apollo.queries.sitePatientsWithPod.refetch();
               return;
            }
            
            this.$apollo.queries.customerPatientsWithPod.skip = false;
            this.$apollo.queries.customerPatientsWithPod.refetch();
         },
         async deleteAllCustomerPatients() {
            await this.$apollo
                      .mutate(
                          {
                             mutation: DeleteCustomerPatientsToPod,
                             variables: {
                                input: {
                                   customerId: this.selectedCustomer,
                                   podId: this.podId,
                                   updatedByUserId: this.createdByUserId
                                }
                             }
                          })
                      .then(async (data) => {
                         if (data.data.payload.error) {
                            return await this.failure(data.data.payload.errorCode);
                         }
                         return await this.success();
                      })
                      .catch(async (error) => {
                         await this.failure(error);
                      });
            
         },
         async deleteAllSitePatients() {
            await this.$apollo
                      .mutate(
                          {
                             mutation: DeleteSitePatientsToPod,
                             variables: {
                                input: {
                                   customerId: this.selectedCustomer,
                                   podId: this.podId,
                                   updatedByUserId: this.createdByUserId,
                                   siteId: this.selectedSite
                                }
                             }
                          })
                      .then(async (data) => {
                         if (data.data.payload.error) {
                            return await this.failure(data.data.payload.errorCode);
                         }
                         return await this.success();
                      })
                      .catch(async (error) => {
                         await this.failure(error);
                      });
            
         },
         async deleteSelectedMemberships() {
            let ids = [];
            this.selectedPatients.forEach((item) => {
               ids.push(item.patientId);
            });
            
            await this.$apollo
                      .mutate(
                          {
                             mutation: DeletePodPatients,
                             variables: {
                                input: {
                                   customerId: this.selectedCustomer,
                                   podId: this.podId,
                                   updatedByUserId: this.createdByUserId,
                                   patientIds: ids
                                }
                             }
                          })
                      .then(async (data) => {
                         if (data.data.payload.error) {
                            return await this.failure(data.data.payload.errorCode);
                         }
                         return await this.success();
                      })
                      .catch(async (error) => {
                         await this.failure(error);
                      });
         }
      },
      apollo: {
         customers: {
            query: GetPodCustomersList,
            variables() {
               return {
                  customerInput: this.customerInput,
                  podId: this.podId
               };
            },
            update(data) {
               return data.pod.customers.data.sort(
                   (a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1);
            },
            // Disable query on component load.
            // We need to execute query when component is created and props are passed 
            skip() {
               return this.skipGetCustomersList;
            }
         },
         sites: {
            query: GetPodCustomerSiteList,
            variables() {
               return {
                  siteInput: this.siteInput,
                  customerId: this.selectedCustomer,
                  podId: this.podId
               };
            },
            update(data) {
               return data.customer.podSites.data.sort(
                   (a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1);
            },
            skip() {
               return this.skipGetCustomersSites;
            }
         },
         customerPatientsWithPod: {
            query: GetPodCustomerPatientList,
            variables() {
               return {
                  patientInput: this.patientInput,
                  customerId: this.selectedCustomer,
                  podId: this.podId
               };
            },
            update(data) {
               this.count = data.customer.patientsWithPod.count;
               return data.customer.patientsWithPod.data.sort(
                   (a, b) => a.lastName.toLowerCase() > b.lastName.toLowerCase() ? 1 : -1);
            },
            skip() {
               return this.skipGetCustomerPatientList;
            }
         },
         sitePatientsWithPod: {
            query: GetPodCustomerSitePatientList,
            variables() {
               return {
                  patientInput: this.patientInput,
                  siteId: this.selectedSite,
                  customerId: this.selectedCustomer,
                  podId: this.podId
               };
            },
            update(data) {
               this.count = data.customer.site.patientsWithPod.count;
               return data.customer.site.patientsWithPod.data.sort(
                   (a, b) => a.lastName.toLowerCase() > b.lastName.toLowerCase() ? 1 : -1);
            },
            skip() {
               return this.skipGetCustomerSitePatientList;
            }
         }
      },
      created() {
         this.triggerCustomerListQuery();
      }
   };
</script>

<style scoped lang="scss">

</style>