<template>
   <v-container>
      <v-row>
         <v-col class="col-5 d-flex">
            <v-card-subtitle class="ml-4 align-self-center font-weight-black pr-0">Pod Name</v-card-subtitle>
            <v-card-subtitle class="text-subtitle-1 pt-0 pb-0 align-self-center" v-if="name">
               {{name}}
            </v-card-subtitle>
         </v-col>
         
         <v-col class="col-5 d-flex">
            <v-card-subtitle class="align-self-center font-weight-black pr-0">Pod Description</v-card-subtitle>
            <v-card-subtitle class="text-subtitle-1 pt-0 pb-0 align-self-center" v-if="description">
               {{description}}
            </v-card-subtitle>
         </v-col>
      </v-row>
   </v-container>
</template>

<script>
   export default {
      name: "PodDescription",
      props: {
         description: String,
         name: String
      }
   };
</script>

<style scoped>

</style>