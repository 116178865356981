<template>
  <v-main>
    <v-container>

      <v-btn @click="goBack" class="mt-4">Back</v-btn>
      <v-btn class="float-right secondary mt-4 ml-4" v-if="displayActionButton && viewBulkNurseAssignmentBtn"
             @click="handleBulkInsertClick()">
        {{ assignmentBtnText }}
      </v-btn>
      <v-btn class="float-right primary mt-4" v-if="displayActionButton" @click="deleteDialog = true">Delete Patients
      </v-btn>
      <v-btn class="float-right primary mt-4 mr-4" v-if="displayActionButton" @click="addDialog = true">Add Patients
      </v-btn>

      <!-- Add Membership Dialog-->
      <v-dialog v-model="addDialog" max-width="800px" persistent v-if="addDialog">
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="addDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Add Patients</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn dark text @click="onAddPatientCanceled()">Close</v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <add-patient v-if="addDialog && podId" :podId="podId"
                     @canceled="onAddPatientCanceled()" @saved="onAddMembershipSaved()">
        </add-patient>
      </v-dialog>
      <!-- END: Add Membership Dialog-->

      <!-- Delete Memberships Dialog-->
      <v-dialog v-model="deleteDialog" max-width="800px" persistent v-if="deleteDialog">
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="deleteDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Delete Patients</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn dark text @click="onDeletePatientCanceled()">Close</v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <delete-patient v-if="deleteDialog && podId" :podId="podId"
                        @canceled="onDeleteMembershipsCanceled()" @deleted="onDeletePatientSaved()">
        </delete-patient>
      </v-dialog>
      <!-- END: Memberships Dialog-->

      <!-- Bulk Assignment Dialog-->
      <v-dialog v-model="showNurseAssigmentModal" max-width="800px" persistent v-if="showNurseAssigmentModal">
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="closeShowNurseAssigmentModal()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Bulk Nurse Assignment</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn dark text @click="closeShowNurseAssigmentModal()">Close</v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <bulk-nurse-assignment v-if="showNurseAssigmentModal" :selectedPatients="selectedPatients" :podId="podId"
                               @canceled="closeShowNurseAssigmentModal()">
        </bulk-nurse-assignment>
      </v-dialog>
      <!-- END:  Bulk Assignment Dialog-->

      <!--Memberships List-->
      <patient-list v-if="podId" :podId="podId" :bulkInsert="bulkInsert" :displayEdit="displayActionButton"
                    :displayDelete="displayActionButton" :key="renderList"
                    @deleted="onDeletePatientSaved()" @patients="handleSelectedPatients"></patient-list>
      <!--END: Memberships List-->

    </v-container>
  </v-main>
</template>

<script>
  import router from "@/router";
  import PatientList from "../components/Pods/Patients/PatientList";
  import AddPatient from "../components/Pods/Patients/AddPatient";
  import BulkNurseAssigment from "../components/Pods/Patients/BulkNurseAssignment";
  import DeletePatient from "../components/Pods/Patients/DeletePatient";
  import {Input} from "@/types/queryInput";
  import {PodAccessService} from "@/services/PodAccessService";
  import BulkNurseAssignment from "@/components/Pods/Patients/BulkNurseAssignment.vue";
  
  export default {
    name: "PodPatients",
    components: {
      BulkNurseAssignment,
      PatientList,
      AddPatient,
      DeletePatient
    },
    data: () => ({
      podId: null,
      addDialog: false,
      deleteDialog: false,
      renderList: 0,
      podAccessServices: null,
      bulkInsert: false,
      showNurseAssigmentModal: false,
      selectedPatients: [],
      viewBulkNurseAssignmentBtn: process.env.VUE_APP_BULK_NURSE_ASSIGNMENT_FEATURE === "enabled"
    }),
    computed: {
      userRoleInput() {
        return new Input();
      },
      podAccess() {
        if (!this.podAccessServices) {
          return null;
        }
        return this.podAccessServices;
      },
      displayActionButton() {
        return this.podAccess && this.podAccess.displayMembershipActionByPodAssignment();
      },
      assignmentBtnText() {
        if (this.bulkInsert) {
          return "Select Nurse";
        }
        return "Bulk Assignment";
      }
    },
    methods: {
      goBack() {
        router.back();
      },
      async onAddPatientCanceled() {
        this.addDialog = false;
      },
      async onDeletePatientCanceled() {
        this.deleteDialog = false;
      },
      async onAddMembershipSaved() {
        this.addDialog = false;
        this.renderList += 1;
      },
      async onDeleteMembershipsCanceled() {
        this.deleteDialog = false;
      },
      async onDeletePatientSaved() {
        this.deleteDialog = false;
        this.renderList += 1;
      },
      closeShowNurseAssigmentModal() {
        this.showNurseAssigmentModal = false;
        this.bulkInsert = false;
        this.selectedPatients = [];
        this.renderList += 1;
      },
      handleBulkInsertClick() {
        if (this.bulkInsert) {
          this.showNurseAssigmentModal = true;
        }
        this.bulkInsert = true;
      },
      handleSelectedPatients(p) {
        this.selectedPatients = p;
      }
    },
    async created() {
      this.podId = this.$route.params.podId;
  
      let service = new PodAccessService(this.$store.state.achillesUser.userId, this.podId, this.$apollo);
      //get user pod role for action buttons
      await service.getUser()
          .then(() => {
            this.podAccessServices = service;
            this.podAccessServices.setIsSuperAdmin();
          });
    }
  };
</script>

<style scoped lang="scss">

</style>