<template>
    <div>
        <v-autocomplete v-model="model"
                        :items="results"
                        :loading="isLoading"
                        :search-input.sync="search"
                        :disabled="isReadonly"
                        color="gray"
                        item-text="name"
                        item-value="siteId"
                        placeholder="Search Sites"
                        prepend-icon="mdi-magnify"
                        return-object
                        no-filter
                        append-icon=""
                        @click:clear="clicked"
                        ref="searchInput"
                        clearable
                        autofocus
                        :menu-props="{ maxWidth: !isMobile ? '450' : 'auto', minWidth: !isMobile ? '450' : '0', maxHeight: '500' }">
            <template v-slot:progress>
                <v-progress-linear color="white"
                                   height="1"
                                   indeterminate
                                   absolute
                                   top></v-progress-linear>
            </template>
            <template slot="no-data">
                <v-list-item>
                    <v-list-item-subtitle v-if="onlyHintInSearch">
                        <p>
                            Enter a search term for the search hint...
                        </p>
                    </v-list-item-subtitle>
                    <v-list-item-subtitle v-if="notFound && !isLoading && !onlyHintInSearch && search && !onlyHintInSearch">
                        <p>
                            Your search - <strong>{{search}}</strong> - did not match any
                            Sites
                        </p>
                        <div>Suggestions:</div>
                        <div>
                            <ul>
                                <li>Make sure all words are spelled correctly</li>
                                <li>Try different search terms, last name, designation etc.</li>
                            </ul>
                        </div>
                    </v-list-item-subtitle>
                    <v-list-item-subtitle class="text-wrap" v-if="(!notFound && !isLoading && !onlyHintInSearch) || !search">
                        <p>Type to start searching...</p>
                        <p class="">
                            For search hints, click or type
                            <span class="font-weight-medium">[hint]:[searchterm]</span>
                            e.g. name:Omaha
                        </p>
                        <p>
                            <v-btn color="primary" outlined text class="text-lowercase mt-1" small @click="selectText('siteId:')">
                                siteId:
                            </v-btn>
                            <v-btn color="primary" outlined text class="text-lowercase mt-1" small @click="selectText('name:')">
                                name:
                            </v-btn>
                        </p>
                    </v-list-item-subtitle>
                    <v-list-item-subtitle v-if="isLoading">
                        Searching...
                    </v-list-item-subtitle>
                </v-list-item>
            </template>
            <template v-slot:item="{ item }">
                <v-list-item-avatar color="indigo"
                                    class="headline font-weight-light white--text">
                    {{item.name.charAt(0)}}
                </v-list-item-avatar>
                <v-list-item-content>
                    <v-list-item-title v-text="item.name"></v-list-item-title>
                </v-list-item-content>
            </template>
        </v-autocomplete>
    </div>
</template>
<script>
    import GlobalSearchService from "../services/GlobalSearchService";
    import SiteService from "../services/SiteService"
    import VueLodash from "vue-lodash";
    import lodash, { debounce } from "lodash";
    import Vue from "vue";

    Vue.use(VueLodash, {
        name: "custom",
        lodash: lodash
    });

    export default {
        props: {
            customerId: Number,
            isReadonly: Boolean
        },
        data: () => ({
            results: [],
            isLoading: false,
            model: { name: "" },
            search: null,
            providerID: 0,
            selectedCustomerId: 0,
            providerSelected: false,
            providerId: 0,
            count: 0,
            site: {
                customerId: null,
                customerName: null,
                siteId: null,
                siteName: null,
                stationNumber: null,
            },
            componentKey: 0,
            notFound: false,
            queryId: "",
            debounceMs: 500,
        }),
        created: function () {
            this.querySelections = debounce(this.querySelections, this.debounceMs);
        },
        computed: {
            onlyHintInSearch: function () {
                if (!this.search) {
                    return false;
                }
                return this.search[this.search.length - 1] === ":";
            },
            isMobile() {
                return this.$vuetify.breakpoint.xsOnly;
            }
        },
        watch: {
            search: function (val) {
                this.querySelections(val);
            },
            model(newModel) {
                if (newModel !== undefined) {
                    this.search = null;
                    this.customerId = this.$props.customerId;
                    this.siteId = newModel.siteId;
                    this.name = newModel.name;
                    this.lookupSite();
                }
            }
        },
        methods: {
            selectText(value) {
                this.search = value;
                setTimeout(() => {
                    this.$refs.searchInput.focus();
                    this.$refs.searchInput.$el.getElementsByTagName("input")[0].setSelectionRange(value.length,
                        value.length);
                });
            },

            querySelections(val) {
                if (val === "" || val === null || this.onlyHintInSearch) {
                    this.results = [];
                    this.$emit('cleared');
                    return;
                }
                this.notFound = false;
                this.queryId = Date.now() + "" + Math.random();
                this.getResults(val, this.queryId);

            },
            toTitleCase(str) {
                return str.replace(/(^|\s)\S/g, function (t) {
                    return t.toUpperCase();
                });
            },

            forceRerender() {
                this.componentKey += 1;
            },

            getResults: function (val, queryId) {
                if (val !== "" && val !== undefined && val !== null) {
                    this.isLoading = true;
                    let pagedOptions = {
                        Search: val,
                        SortDesc: false,
                        ItemsPerPage: 15,
                        Page: 1
                    };
                    new GlobalSearchService()
                        .searchSites(this.$props.customerId, pagedOptions)
                        .then((value) => {
                            if (this.queryId !== queryId) {
                                return;
                            } // Later query running
                            if (this.search) {
                                let newResults = value.items;
                                this.count = value.totalCount;
                                newResults.map((entry) => {
                                    return entry;
                                });

                                if (this.queryId === queryId) {  // Only if last latest query
                                    this.results = newResults;
                                }
                                if (value.totalCount === 0) {
                                    this.notFound = true;
                                }
                            } else {
                                this.results = [];
                                this.count = 0;
                            }
                        })
                        .catch((err) => {
                            console.log(err);
                        })
                        .finally(() => (this.isLoading = false));
                }
            },
            lookupSite: function () {
                this.isLoading = true;
                new SiteService()
                    .get(this.$props.customerId, this.siteId)
                    .then((value) => {
                        this.site = Object.assign({}, value);
                        this.site.siteId = value.siteId;
                        this.site.name = value.name;
                        this.$emit('site-selected', this.site);
                    })
                    .finally(() => (this.isLoading = false));
            },
            clicked: function () {
                this.results = [];
            },
        }
    };
</script>

<style scoped>
    .v-snack:not(.v-snack--absolute) {
        z-index: 9999;
    }
</style>