<template>
  <v-card>
    <v-container class="pt-0">
      <v-card-text class="pt-0">
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-row>
            <v-col col="6">
              <v-select ref="assignmentFor" :items="assignmentForItems" :label="assignmentForLabel" item-text="text"
                        item-value="id" @change="getSelectionFor"
                        required v-model="selectedAssignmentFor" class="ml-4"></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col col="6">
              <v-autocomplete ref="selectionFor" v-model="selectedSelectionFor"
                              :items="selectionForItems" :loading="loading" @change="clearSearch()"
                              :search-input.sync="search" item-value="id"
                              :return-object="true" dense no-filter multiple chips
                              label="" item-text="text" class="ml-4">
                <template v-slot:selection="data">
                  <v-chip v-bind="data.attrs" :input-value="data.selected.id" :key="data.item.id" close
                          @click="data.select"
                          @click:close="removeSelectedUser(data.item.id)">
                    {{ data.item.text }}
                  </v-chip>
                </template>
                <template v-slot:item="data">
                  <v-list-item-content>
                    <v-list-item-subtitle
                        v-html="data.item.text"></v-list-item-subtitle>
                  </v-list-item-content>
                </template>
                <template v-slot:append-item>
                  <div v-intersect="endIntersect"/>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col col="6">
              <v-select ref="primaryNurseSelection" :items="getPodNursesItems"
                        :label="primaryNurseSelectionLabel" item-text="text"
                        item-value="id"
                        required v-model="selectedPrimaryNurseSelection" class="ml-4"></v-select>
            </v-col>
          </v-row>
          <v-container style="height: 80px;" v-show="isInProgress">
            <v-row class="fill-height" align-content="center" justify="center">
              <v-col class="text-subtitle-1 text-center indigo--text text--darken-4 font-weight-bold" cols="12">
                Bulk Nurse Assigment is in progress.
              </v-col>
              <v-col cols="6">
                <v-progress-linear color="indigo darken-4" indeterminate rounded height="6"></v-progress-linear>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" @click="onCancel" text>Cancel</v-btn>
        <v-btn color="blue darken-1" @click="onSave" text>Save</v-btn>
      </v-card-actions>
    </v-container>
  </v-card>
</template>

<script>

import {PodRoleEnum} from "@/types/enum";
import GetPodInfo from "./queries/pod-info.graphql";
import UpdateBulkPatientPrimaryNurseUser
  from "@/components/Pods/Patients/mutations/update-bulk-patient-primary-nurse-user.graphql";

export default {
  name: "BulkNurseAssignment",
  props: {
    podId: Number,
    selectedPatients: [],
    podPrimaryNurses: []
  },
  data: () => ({
    searchWord: null,
    page: 1,
    search: null,
    pod: null,
    isInProgress: false,
    valid: true,
    selectedAssignmentFor: null,
    selectedPrimaryNurseSelection: null,
    selectedSelectionFor: null,
    primaryNurseSelection: null,
    selectionForLabel: "Select",
    primaryNurseSelectionLabel: "Primary Nurse",
    primaryNurseSelectionItems: [],
    selectionForItems: [],
    assignmentForLabel: "Assign Primary Nurse for:",
    assignmentForItems: [
      {
        id: "ByCustomer",
        text: "By Customer"
      },
      {
        id: "BySite",
        text: "By Site"
      },
      {
        id: "BySelectedPatients",
        text: "By Selected Patients"
      }
    ],
    loading: false,
    byCustomer: "ByCustomer",
    bySite: "BySite",
    bySelectedPatients: "BySelectedPatients",
  }),
  components: {},
  computed: {
    getPodNursesItems() {
      let records = this.pod && this.pod.nurses ? this.pod.nurses.data : [];
      return records.map(elem => (
          {
            id: elem.userId,
            text: elem.displayName
          }
      ));
    }
  },
  methods: {
    endIntersect(entries, observer, isIntersecting) {
      // if (isIntersecting) {

      // }
    },
    removeSelectedUser(itemId) {
      let idx = null;
      this.selectedSelectionFor.findIndex((element, index) => {
        if (element.id === itemId) {
          idx = index;
        }
      });
      if (idx == null) {
        return;
      }
      this.selectedSelectionFor.splice(idx, 1);
      this.selectedSelectionFor = [...this.selectedSelectionFor];
    },
    clearSearch() {
      this.resetSelection();
    },
    onCancel() {
      this.isInProgress = false;
      this.$emit("canceled");
    },
    onSave() {
      this.saveBulkNurseAssignment();
    },
    getCustomersByPodId() {
      let records = this.pod && this.pod.customers ? this.pod.customers.data : [];
      if (this.searchWord !== null && this.searchWord !== "") {
        records = records.filter((r) => r.name.toLowerCase().indexOf(this.searchWord.toLowerCase()) !== -1);
      }
      this.selectionForItems =
          records.map(elem => (
              {
                id: elem.customerId,
                text: elem.name
              }
          ));
    },
    getSitesByPodId() {
      let customers = this.pod && this.pod.customers ? this.pod.customers.data : [];

      this.selectionForItems = [];

      if (this.searchWord !== null && this.searchWord !== "") {
        customers.map(elem => elem.sites && elem.sites.data && elem.sites.data.map(s => this.searchWord !== null && this.searchWord !== "" && s.name.toLowerCase().indexOf(this.searchWord.toLowerCase()) !== -1 &&
                this.selectionForItems.push({
                  id: elem.customerId + ':' + s.siteId,
                  text: s.name
                })
            )
        );
      } else {
        customers.map(elem => elem.sites && elem.sites.data && elem.sites.data.map(s =>
                this.selectionForItems.push({
                  id: elem.customerId + ':' + s.siteId,
                  text: s.name
                })
            )
        );
      }

    },
    getSelectedPatients(selectedPatients) {
      this.selectionForItems = selectedPatients.map(elem => (
          {
            id: elem.patientId,
            text: elem.patientFirstName + ' ' + elem.patientLastName
          }
      ));
    },
    resetSelection() {
      if (this.selectedAssignmentFor === this.byCustomer) {
        this.getCustomersByPodId();
      } else if (this.selectedAssignmentFor === this.bySite) {
        this.getSitesByPodId();
      } else if (this.selectedAssignmentFor === this.bySelectedPatients) {
        this.getSelectedPatients(this.selectedPatients);
      }
    },
    getSelectionFor() {
      this.selectionForItems = [];
      this.selectedSelectionFor = null;
      this.resetSelection();
    },
    saveBulkNurseAssignment() {
      this.changePrimaryCareNurse();
    },
    triggerQuery() {
      this.loading = true;
      this.$apollo.queries.pod.skip = false;
      this.$apollo.queries.pod.refetch();
    },
    async success() {
      this.isInProgress = false;

      let options = {
        text: "Bulk Nurse Assignment completed successfully",
        title: "Saved",
        actions: ["Okay"]
      };
      await this.$dialog.info(options);
      this.onCancel();


    },
    async validationMessage(message) {
      this.isInProgress = false;

      let options = {
        text: message,
        title: "Validation Error",
        actions: ["Okay"]
      };
      await this.$dialog.info(options);

    },

    async changePrimaryCareNurse() {
      let input = {
        updatedBy: this.selectedAssignmentFor,
        assignments: null,
        bulkPatients: this.selectedPatients,
        primaryNurseUserId: this.selectedPrimaryNurseSelection,
        updatedByUserId: this.$store.state.achillesUser.userId,
        podId: this.podId
      };

      if (input.updatedBy === this.byCustomer || input.updatedBy === this.bySite) {
        input.assignments = this.selectedSelectionFor.map(elem => (
            {
              customerId: parseInt(elem.id.toString().indexOf(":") !== -1 ? elem.id.split(":")[0] : elem.id),
              siteId: elem.id.toString().indexOf(":") !== -1 ? parseInt(elem.id.split(":")[1]) : null
            }
        ));
      }

      if (input.updatedBy === null || input.updatedBy === "") {
        await this.validationMessage("Please select \"Assign Primary Nurse for:\"");
        return;
      } else {
        if (input.updatedBy === this.bySelectedPatients) {
          if (input.bulkPatients === null || input.bulkPatients.length === 0) {
            await this.validationMessage("Please select patients(s) from Patients listing.");
            return;
          }
        } else {
          if ((input.updatedBy === this.byCustomer || input.updatedBy === this.bySite) && input.assignments.length === 0) {
            let message = "";
            if (input.updatedBy === this.byCustomer) {
              message = "Please select customer";

            } else if (input.updatedBy === this.bySite) {
              message = "Please select site";
            }
            await this.validationMessage(message);
            return;
          }
        }

        if (input.primaryNurseUserId === null || input.primaryNurseUserId === "") {
          await this.validationMessage("Please select \"Primary Nurse\"");
          return;
        }
      }

      this.isInProgress = true;
      await this.$apollo.mutate(
          {
            mutation: UpdateBulkPatientPrimaryNurseUser,
            variables: {
              input: input
            }
          })
          .then(async (data) => {
            if (data.data.payload.error) {
              await this.$dialog.error({
                text: data.data.payload.errorCode,
                title: 'Unable to Save'
              });
            } else {
              await this.success();
            }
            await this.$apollo.queries.pod.refetch();
            this.isInProgress = false;
          })
          .catch(async (error) => {
            this.isInProgress = false;
            console.log(error);
            await this.$dialog.error({
              text: 'An error occured while saving',
              title: 'Unable to Save'
            });
          });
    },
  },
  apollo: {
    pod: {
      query: GetPodInfo,
      variables() {
        return {
          podId: this.podId,
          customerInput: {
            podId: this.podId,
            take: 1000,
            skip: 0
          },
          siteInput: {
            take: 1000,
            skip: 0,
            podId: this.podId,
          },
          podRoleIds: [PodRoleEnum.PodNurse, PodRoleEnum.PodAdministrator, PodRoleEnum.PodSupervisor, PodRoleEnum.PodTeamLeader],
          assignedUsersByPodRoleInput: {
            take: 1000,
            skip: 0
          }
        };
      },
      update(data) {
        this.skipGetPodPatientsQuery = true;
        this.loading = false;

        return data.pod;
      }
    }
  },
  created() {
    this.triggerQuery();
  },
  watch: {
    search(val) {
      if (val && typeof val !== 'undefined' && val.length >= 1 && this.searchWord !== val) {
        this.searchWord = val;
        this.resetSelection();
        return;
      }

      if (!val || (val.length === 0 && this.searchWord !== val)) {
        this.searchWord = "";
        this.resetSelection();
      }
    }
  }
};
</script>

<style scoped lang="scss">

</style>